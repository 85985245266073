import { useState } from "react";

const Accordian = ({ title, content }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="transition-all w-full">
      <div
        className="flex items-center gap-4"
        onClick={() => setOpen((prevState) => !prevState)}
      >
        <button
          className={`text-xl w-12 h-12 flex items-center justify-center bg-secondary text-white font-medium rounded-lg`}
        >
          {open ? "-" : "+"}
        </button>
        <div
          className={`text-xl font-bold px-4 w-[calc(100%-4rem)] py-2 bg-secondary rounded-lg text-white `}
        >
          {title}
        </div>
      </div>
      <div className={`ml-16 ${open && "py-4"} transition-all`}>
        {open && content}
      </div>
    </div>
  );
};

export default Accordian;
