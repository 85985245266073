import { Button } from "../../../design/components";
import { PhoneIcon } from "../../../design/icons";

const Trust = () => {
  return (
    <section
      className=" w-full py-20 px-4"
      style={{ backgroundImage: "url('/assets/home/trust-bg.png')" }}
    >
      <div className="w-full max-w-[1500px] mx-auto flex flex-col items-center">
        <h2 className="text-4xl lg:text-7xl text-white text-center">
          You can trust us with your
          <br />
          <span className="font-bold">Home & Family</span>{" "}
        </h2>
        <div className="flex items-center flex-col lg:flex-row gap-4 lg:gap-10 mx-auto mt-10">
          <a href="mailto:Donn@housekeepinghq.com">
            <Button theme="white">Get a quote</Button>
          </a>
          <Button theme="white" variant="outline">
            <a href="tel:+253-988-5813">
              <span className="flex items-center gap-2">
                <PhoneIcon /> <span>253-988-5813</span>
              </span>
            </a>
          </Button>
        </div>
      </div>
    </section>
  );
};

export default Trust;
