import { Carousel1 } from "../../../design/components";
import CarouselSlick from "../../../design/components/CarouselSlick";
const images = [
  "/assets/home/how-1.png",
  "/assets/home/how-2.png",
  "/assets/home/how-3.png",
  "/assets/home/how-4.png",
  "/assets/home/how-5.png",
  "/assets/home/how-6.png",
  "/assets/home/how-7.png",
  "/assets/home/how-8.png",
  "/assets/home/how-9.png",
];
const How = () => {
  return (
    <section className="w-full py-10 mt-10">
      <h2 className="text-2xl lg:text-7xl text-black font-bold text-center lg:max-w-[75%] mx-auto px-4">
        Check Out How We Make YOUR HOME SHINES BRIGHTLY WITH US 
      </h2>
      <p className="text-lg lg:text-2xl text-black my-8 text-center mx-auto lg:max-w-[55%] px-4">
        We strive for your complete satisfaction with our job and take pride in
        what we do.  Here are some examples of our recent jobs.
      </p>
      <div className="w-full ">
        <Carousel1 images={images} />
        {/* <CarouselSlick/> */}
      </div>
    </section>
  );
};

export default How;
