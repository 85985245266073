import { useState } from "react";
import { routes } from "../../constants/routes";
import { ExitIcon, PhoneIcon } from "../../design/icons";
import { Twirl as Hamburger } from "hamburger-react";

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <header className="w-full bg-primary fixed  top-0 z-50">
      <div className="w-full max-w-[1500px] mx-auto px-4 py-4 lg:py-6 flex items-center justify-between relative z-10 bg-primary">
        <a href={routes.home} className="w-40 lg:w-60">
          <img src="/assets/logo-real.svg" alt="" width="100%" height="100%" />
        </a>
        <div className="hidden lg:flex items-center gap-12 ">
          <nav className="flex items-center gap-8">
            <Link href={routes.residential}>Residential</Link>
            <Link href={routes.services}>Services</Link>
            <Link href={routes.locations}>Location</Link>
            <Link href={routes.joinCrew}>Join Crew</Link>
          </nav>
          <a
            href="tel:+253-988-5813"
            class="relative inline-flex items-center justify-start  px-5 py-3 overflow-hidden font-bold rounded-full group"
          >
            <span class="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-white opacity-[3%]"></span>
            <span class="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-white opacity-100 group-hover:-translate-x-5"></span>
            <span class="relative w-full text-left text-white transition-colors duration-200 ease-in-out group-hover:text-primary flex items-center gap-2">
              <PhoneIcon />
              <span>253-988-5813</span>
            </span>
            <span class="absolute inset-0 border-2 border-white rounded-full"></span>
          </a>
        </div>
        <div className="cursor-pointer text-white rounded-full overflow-hidden  transition-all duration-500 lg:hidden">
          <Hamburger size={24} toggled={menuOpen} toggle={setMenuOpen} />
        </div>
      </div>
      <div
        className={`bg-primary fixed w-screen h-[calc(100vh-80px)] transition-all duration-300 ease-in-out py-4 px-4 right-0 z-0  ${menuOpen ? "bottom-0" : "bottom-full"
          }`}
      >
        <nav className="flex  flex-col gap-8">
          <Link href={routes.residential}>Residential</Link>
          <Link href={routes.services}>Services</Link>
          <Link href={routes.locations}>Location</Link>
          <Link href={routes.joinCrew}>Join Crew</Link>
        </nav>
        <a
          href="tel:+253-988-5813"
          class="relative inline-flex items-center justify-start mt-10  px-5 py-3 overflow-hidden font-bold rounded-full group text-primary bg-white"
        >
          <PhoneIcon />
          <span>253-988-5813</span>
        </a>
      </div>
    </header>
  );
};

export default Navbar;

const Link = ({ children, href }) => {
  return (
    <a href={href} className="text-white font-semibold cursor-pointer">
      {children}
    </a>
  );
};
