import { Button } from "../../../design/components";
import { PhoneIcon } from "../../../design/icons";

const Features = () => {
	return (
		<section className="w-full max-w-[1500px] mx-auto px-4 py-10 grid grid-cols-1 lg:grid-cols-3 gap-16">
			<Card
				title="Safety Matters"
				icon="/assets/home/feature-1.svg"
				description="We cAREFULLY SCREEN ALL OF OUR CLEANERS, WHO ARE REQUIRED TO PASS IDENTITY CHECKS AND MEET US IN PERSON."
			/>
			<Card
				title="High-Quality Service"
				icon="/assets/home/feature-2.svg"
				description="OUR EXPERT CLEANERS DO MORE THAN THE MINIMUM ON EVERY TASK. THEY RECEIVE RATINGS & FEEDBACK AFTER EACH CLEANING."
			/>
			<Card
				title="CONVENIENT ASSISTANCE"
				icon="/assets/home/feature-3.svg"
				description="WE USE OUR OWN SECURE SUPPLIES. TELL US IF YOU HAVE ANY SPECIFIC NEEDS AND WE’LL ACCOMMODATE THEM."
			/>
			<Card
				title="EASY CONTACT"
				icon="/assets/home/feature-4.svg"
				description="YOU CAN COMMUNICATE ONLINE WITH YOUR CLEANERS AND KEEP IN TOUCH WITH THEM."
			/>
			<Card
				title="ONLINE PAYMENT"
				icon="/assets/home/feature-5.svg"
				description="YOU ONLY PAY ONLINE SAFELY AFTER THE CLEANING IS DONE."
			/>
			<div className="w-full py-8 px-16 border border-secondary rounded-lg">
				<h3 className="text-center text-black text-2xl font-bold">
					Want to Know More?
				</h3>
				<p className="text-center text-black my-4 font-medium">
					You can tell us what you need and we can help!{" "}
				</p>
				<div className="w-max mx-auto">
					<a href="tel:+253-988-5813">
						<Button variant="outline" theme="secondary">
							<span className="flex items-center gap-2">
								<PhoneIcon /> <span>253-988-5813</span>
							</span>
						</Button>
					</a>
				</div>
			</div>
		</section>
	);
};

export default Features;

const Card = ({ icon, title, description }) => {
	return (
		<div
			className="rounded-lg px-8 pb-8 pt-12 relative"
			style={{ boxShadow: "0px 0px 16px 4px rgba(0,0,0,0.1)" }}>
			<div className="bg-primary w-16 h-16 flex items-center justify-center p-4 absolute left-8 top-[-2rem] rounded-lg">
				<img src={icon} alt="" width="100%" height="100%" />
			</div>
			<div>
				<h3 className=" text-black text-2xl font-bold">{title}</h3>
				<p className="text-black my-4 font-medium">{description}</p>
			</div>
		</div>
	);
};
