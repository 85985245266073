import { routes } from "../../constants/routes";
import {
  InstagramIcon,
  RightArrow,
  TwitterIcon,
  YoutubeIcon,
} from "../../design/icons";

const Footer = () => {
  return (
    <footer className="bg-dark w-full">
      <div className="w-full max-w-[1500px] mx-auto py-10 px-4">
        <div className="py-10 flex justify-between flex-col lg:flex-row gap-10">
          <div className="w-full lg:w-[23rem]">
            <a href={routes.home} className="w-[20rem]">
              <img
                src="/assets/logo-real.svg"
                alt=""
                width="100%"
                height="100%"
              />
            </a>
            <p className="text-sm text-textGray my-8">
              We are a professional cleaning company dedicated to providing
              top-quality cleaning services. With a skilled team, eco-friendly
              practices, and a commitment to customer satisfaction, we ensure
              spaces are spotless and hygienic.
            </p>
            <div className="flex items-center gap-8 text-white">
              <TwitterIcon />
              <InstagramIcon />
              <YoutubeIcon />
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            <div className="flex flex-col gap-6">
              <h3 className="text-white font-bold">Home</h3>
              <div className="flex flex-col gap-4">
                <Link>About Us</Link>
                <Link href={routes.services}>Services</Link>
                <Link href={routes.joinCrew}>Join Our Crew</Link>
              </div>
            </div>
            <div className="flex flex-col gap-6">
              <h3 className="text-white font-bold">Residential</h3>
              <div className="flex flex-col gap-4">
                <Link>Office Cleaning</Link>
                <Link>Kitchen Cleaning</Link>
                <Link>Vehicle Cleaning</Link>
              </div>
            </div>
            <div className="flex flex-col gap-6">
              <h3 className="text-white font-bold">Commercial</h3>
              <div className="flex flex-col gap-4">
                <Link>Office Cleaning</Link>
                <Link>Kitchen Cleaning</Link>
                <Link>Vehicle Cleaning</Link>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-[25rem] h-max relative z-0">
            <input
              placeholder="Signup for Discounts"
              type="text"
              className="outline-none bg-[#F9F9FE] px-8 py-8 relative z-0 rounded-full w-full"
            />
            <button className="text-white bg-primary rounded-full absolute z-[1] w-12 h-12 flex items-center justify-center right-4 top-[calc(50%-1.5rem)]">
              <RightArrow size={1.25} />
            </button>
          </div>
        </div>
        <hr className="text-textGray" />
        <div className="flex flex-col-reverse lg:flex-row items-center justify-between gap-4 py-4">
          <h5 className="text-textGray text-sm">
            Copyright © 2024 HouseKeeping Headquarters
          </h5>
          <div className="flex items-center gap-2">
            <Link>
              <span className="text-sm">Terms of Use</span>
            </Link>
            <div className="w-[1px] h-[14px] bg-textGray" />
            <Link>
              <span className="text-sm">Privacy Policy</span>
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

const Link = ({ children, href }) => {
  return (
    <a
      href={href}
      className="text-textGray hover:text-white transition-all duration-300  cursor-pointer"
    >
      {children}
    </a>
  );
};
