import { useState } from "react";
import { Button } from "../../../design/components";
import { PhoneIcon } from "../../../design/icons";
import BoardingForm from "../BoardingForm";

const Hero = () => {
  const [getQuote, setGetQuote] = useState(false);
  const handleGetQutoeClick = () => {
    setGetQuote(true);
  };
  return (
    <section className="w-full xl:max-h-[600px] max-w-[1500px] mx-auto 2xl:h-[65vh] relative px-4 py-10 lg:py-20 z-0 flex flex-col lg:flex-row gap-10 justify-between">
      <div className="absolute bottom-20 lg:bottom-0 left-[50%] w-full lg:w-[40%] z-0">
        <img src="/assets/home/hero.png" alt="" width="100%" height="100%" />
      </div>
      <div className="w-full lg:w-[60%] relative z-[1]">
        <h1 className="text-4xl lg:text-8xl font-bold text-center lg:text-left">
          The Puget Sound's Premier <br />
          <span className="text-primary">Cleaning Service</span>
        </h1>
        <h3 className="text-xl lg:text-3xl font-medium my-6 text-center lg:text-left">
          Bringing Happiness through Cleanliness
        </h3>
        <div className="flex  items-center lg:items-start justify-center lg:justify-start flex-col lg:flex-row gap-4">
          <a href="mailto:Donn@housekeepinghq.com">
            <div onClick={handleGetQutoeClick}>
              <a href="mailto:Headquarters@housekeepinghq.com">
                <Button theme="secondary">Get a quote</Button>
              </a>
            </div>
          </a>
          <a href="tel:+253-988-5813">
            <Button variant="outline" theme="black">
              <span className="flex items-center gap-2">
                <span>
                  <PhoneIcon />
                </span>
                <span>253-988-5813</span>
              </span>
            </Button>
          </a>
          <img className="" src="/assets/Group 1000003093.png" alt="" />
        </div>
      </div>
      <div className="relative z-[1] lg:-translate-y-8">
        <BoardingForm />
      </div>
    </section>
  );
};

export default Hero;
