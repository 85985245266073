import { useEffect, useRef, useState } from "react";
import { Button, TextField } from "../../design/components";
import { RightArrow } from "../../design/icons";
import { sendEmail } from "../../services/email";

const BoardingForm = () => {
  const [board, setBoard] = useState(1);
  const formRef = useRef(null);

  return (
    <div
      className="bg-white/60 rounded p-4 backdrop-blur-md"
      style={{ boxShadow: "0px 0px 40px 16px rgba(0,0,0,0.1)" }}
    >
      <div className="flex items-center gap-4"></div>
      <div>
        <div className="w-min">
          <h2 className="text-2xl mt-2 mb-2 lg:text-4xl font-bold text-black w-max text-center">
            Get Your Instant Quote
          </h2>
          <p className="lg:text-lg text-black text-center">
            HHQ collects this information to better understand and serve your
            business.
          </p>
        </div>
        <form
          ref={formRef}
          onSubmit={(e) => {
            sendEmail(
              e,
              formRef.current,
              process.env.REACT_APP_EMAIL_CTA_TEMPLATE_ID
            );
          }}
          action=""
          className="w-full grid grid-cols-1 md:grid-cols-2 gap-4 py-4"
        >
          <div className="col-span-full">
            <TextField label="Type Of Service" />
          </div>
          <TextField label="Name" naame="boarding_form_1_name" />
          <TextField label="Email" naame="boarding_form_1_email" />
          <TextField label="Address 1" naame="boarding_form_1_address_1" />
          <TextField label="Address 2" naame="boarding_form_1_address_2" />
          <div className="col-span-full">
            <Button theme="secondary" fullWidth type="button">
              <span className="flex items-center gap-2 w-max">
                <span>Submit</span>
                <span>
                  <RightArrow />
                </span>
              </span>
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BoardingForm;
